import React from 'react'

const Home = () => {
  return (
    <div style={{paddingTop: '64px' }}>Home


    </div>
  )
}

export default Home